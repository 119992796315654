import { themeSubject, setTheme, DEFAULT_THEME } from './theme-subject';
import { showNavSubject, setShowNav } from './navbar-subject';
import { browserHistory, setBrowserHistory } from './history-subject';
import {
  authStatusSubject,
  setAuthStatus,
  tokenSubject,
  setToken,
} from './auth-subject';

export {
  themeSubject,
  setTheme,
  showNavSubject,
  setShowNav,
  browserHistory,
  setBrowserHistory,
  authStatusSubject,
  setAuthStatus,
  tokenSubject,
  setToken,
  DEFAULT_THEME,
};
