import GET_VERSIONS from './about';
import GET_ORGANISATION from './organisation';
import { ME_QUERY, UPDATE_USER, UPDATE_USER_SETTINGS } from './me';

export {
  GET_VERSIONS,
  GET_ORGANISATION,
  ME_QUERY,
  UPDATE_USER,
  UPDATE_USER_SETTINGS,
};
